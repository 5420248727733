import BaseController from '@/service/BaseService'
import ToolRequest from '@/request/Business/ToolRequest'
import { errorTip } from '@/utils/Tip'

const base = new BaseController()

export default {
  data () {
    return {
      list: [],
      teacher: [],
      teacher_id: '',
      message: '',
      fullscreenLoading: false
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new ToolRequest())
    await this.getList()
  },
  methods: {
    async getList () {
      const result = await base.request.listPost()
      if (result.code !== 200) return
      this.teacher = result.data.teacher
    },
    // 官网导入
    webSiteClick: async function () {
      const teacherId = this.teacher_id
      const webSite = this.list
      if (teacherId === null || teacherId === '') errorTip(this, '请选择老师')
      if (webSite.length === 0) errorTip(this, '未导入网站')
      this.fullscreenLoading = true
      try {
        this.message = ''
        // 将所有操作添加进任务队列中
        const tasks = await Promise.allSettled(
          webSite.map(async (webSiteItem, index) => {
            webSiteItem.teacherId = teacherId
            // 模拟异步操作
            await new Promise(resolve => setTimeout(resolve, index * 2000))
            return base.request.addPost(webSiteItem)
          })
        )

        // 等待所有操作完成
        tasks.forEach(result => {
          console.log(result)
          if (result.status === 'fulfilled') {
            this.message += `${result.value.message}\r\n`
          } else {
            this.message += '请求失败\r\n'
          }
        })
        this.fullscreenLoading = false
      } catch (e) {
        this.fullscreenLoading = false
        errorTip(this, e.message)
      }
    },
    // 宝塔建站
    baoTaClick: async function () {
      const webSite = this.list
      if (webSite.length === 0) errorTip(this, '未导入网站')
      this.fullscreenLoading = true

      try {
        this.message = ''
        // 将所有操作添加进任务队列中
        const tasks = await Promise.allSettled(
          webSite.map(async (webSiteItem, index) => {
            // 模拟异步操作
            await new Promise(resolve => setTimeout(resolve, index * 2000))
            return base.request.baoTaPost(webSiteItem)
          })
        )

        // 等待所有操作完成
        tasks.forEach(result => {
          console.log(result)
          if (result.status === 'fulfilled') {
            this.message += `${result.value.message}\r\n`
          } else {
            this.message += '请求失败\r\n'
          }
        })
        this.fullscreenLoading = false
      } catch (e) {
        this.fullscreenLoading = false
        errorTip(this, e.message)
      }
    },

    // 邮箱
    mailClick: async function () {
      const webSite = this.list
      if (webSite.length === 0) errorTip(this, '未导入网站')
      this.fullscreenLoading = true

      try {
        this.message = ''
        // 将所有操作添加进任务队列中
        const tasks = await Promise.allSettled(
          webSite.map(async (webSiteItem, index) => {
            // 模拟异步操作
            await new Promise(resolve => setTimeout(resolve, index * 2000))
            return base.request.mailPost(webSiteItem)
          })
        )

        // 等待所有操作完成
        tasks.forEach(result => {
          console.log(result)
          if (result.status === 'fulfilled') {
            this.message += `${result.value.message}\r\n`
          } else {
            this.message += '请求失败\r\n'
          }
        })
        this.fullscreenLoading = false
      } catch (e) {
        this.fullscreenLoading = false
        errorTip(this, e.message)
      }
    },

    // 导入事件
    importClick: async function ({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      const result = await base.request.importPost(formData)
      this.list = result.data
    }
  }
}
