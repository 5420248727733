import request from '../../utils/request'

export default class ToolRequest {
  // 获取列表
  async listPost (data) {
    return await request.post('/Business/Tool/Select', data)
  }

  // 添加操作
  async addPost (data) {
    return request.post('/Business/Tool/Insert', data)
  }

  // 宝塔建站
  async baoTaPost (data) {
    return request.post('/Business/Tool/BaoTa', data)
  }

  // 宝塔建站
  async mailPost (data) {
    return request.post('/Business/Tool/Mail', data)
  }

  async getCertificatePost () {
    return request.post('/Business/Tool/GetCertificate')
  }

  saveCertificatePost (data) {
    console.log('进入了这个方法')
    return request.post('/Business/Tool/SaveCertificate', data)
  }

  // 编辑操作
  async editPost (data) {
    return request.post('/Business/Tool/Edit', data)
  }

  // 删除操作
  async deletePost (data) {
    return request.post('/Business/Tool/Delete', data)
  }

  // 导出操作
  async exportPost (data) {
    return request.post('/Business/Tool/Export', data)
  }

  // 导入操作
  async importPost (data) {
    console.log(data)
    return request.post('/Business/Tool/Import', data)
  }
}
